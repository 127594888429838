import React from 'react';

function AdvicePage() {
  // Advice data
  const adviceList = [];
  
  return (
    <div className="advice-page">
      <ul>
        {adviceList.map((advice, index) => (
          <li key={index}>{advice}</li>
        ))}
      </ul>
      <a
        href="https://palorahcapital.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button>Project Advice</button>
      </a>
    </div>
  );
}

export default AdvicePage;
